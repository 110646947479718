/*
 * @Description: 
 * @Version: 2.0
 * @Autor: yuBiao
 * @Date: 2021-08-30 10:00:45
 * @LastEditors: yuBiao
 * @LastEditTime: 2022-04-19 11:29:39
 */
import request from '../../util/request';
import { CRM, BASIC, CLUE, AI } from '../apiConst';

export default {
  //获取CRM客户消息条数
  getMessageNum (id) {
    return request.get({ url: `${CRM}/crm/customer/v1/query-customer-num?estateId=${id}` });
  },
  //分页查询通知提醒消息
  getMsgPage (data) {
    return request.post({ url: `${BASIC}/notify/v1/msg-page`, data, hideLoading: true });
  },
  //分页查询内容库数据
  getCmePage (data) {
    return request.post({ url: `${CLUE}/mar/cme/v1/cme-page`, data});
  },
  //获取cme内容URL
  getCmeUrl(taskId, params) {
    return request.get({url: `${CLUE}/mar/cme/v1/cme-url/${taskId}`, params});
  },
  //获取工作台网页相关任务详情
  getTaskDetail(params) {
    return request.get({url: `${CLUE}/task/executor/v1/cme/info`, params});
  },
  // 打开Ai名片
  getAIUrl(data) {
    return request.post({url: `${AI}/share/v1/ai-card-init`, data});
  },
};
