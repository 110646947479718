<!--置业顾问工作台-->
<template>
  <div class="home">
    <!--    crm 数据统计  v-if="showCrm" -->
    <!-- <div class="top" id="top" v-if="showCrm">
      <div class="crm-list" id="crmList">
        <div
          class="list-item"
          v-for="(item, index) of crmList"
          :key="index"
          @click="goCustomer(item)"
        >
          <div class="bg" :class="item.class">
            <span class="bg-num">{{ item.num }}</span>
          </div>
          <div class="name">{{ item.name }}</div>
        </div>
      </div>
    </div> -->
    <div class="box" style="top: 0px">
      <div class="apps">
        <div
          class="app-item"
          v-show="item.show"
          v-for="(item, index) of apps"
          :key="index"
          @click="junmpApp(item)"
        >
          <img :src="item.url" class="item-icon" />
          <div class="item-name">{{ item.name }}</div>
        </div>
      </div>
      <!--  关键用户   && v-if="showPotential" keyUserList.length > 0 -->
      <div class="keyUser-box" v-if="showPotential && keyUserList.length > 0">
        <div class="keyUser-list" @click="$router.push('/keyUser')">
          <h6>关键用户</h6>
          <p>通过他们可以触达到更多的客户资源</p>
          <div class="keyUser-head">
            <template v-if="keyUserList.length > 4">
              <img
                :src="item.avatar"
                alt=""
                :onerror="defaultImg"
                v-for="item of keyUserListSlice"
                :key="item.clueId"
              />
            </template>
            <template v-else>
              <img
                :src="item.avatar"
                alt=""
                :onerror="defaultImg"
                v-for="item of keyUserList"
                :key="item.clueId"
              />
            </template>
          </div>
          <svg class="icon icon-right" aria-hidden="true">
            <use xlink:href="#icona-1212jiantou"></use>
          </svg>
        </div>
      </div>
      <div class="remind-box" v-if="msgList && msgList.length > 0">
        <div class="remind" @click="$router.push('/consultant/remindDetail')">
          <img src="@/assets/images/提醒_bg.png" class="remind-bg" />
          <span class="remind-title">提醒</span>
          <van-swipe
            :show-indicators="false"
            class="remind-content"
            autoplay="3000"
            vertical
          >
            <van-swipe-item v-for="(item, index) of msgList" :key="index">
              <div class="time">{{ item.createTime }}</div>
              <div class="content">
                <span>{{ item.msgType | filterMsgType }}</span>
                {{ item.context }}
              </div>
            </van-swipe-item>
          </van-swipe>
          <svg class="icon" aria-hidden="true">
            <use xlink:href="#icona-1212jiantou"></use>
          </svg>
        </div>
      </div>
      <div class="task">
        <div class="task-title" @click="$router.push('/taskList')">
          <div class="title">待办任务</div>
          <svg class="icon" aria-hidden="true">
            <use xlink:href="#icona-1212jiantou"></use>
          </svg>
        </div>
        <div class="task-list">
          <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
            <van-list
              v-if="taskList.length > 0"
              v-model="loading"
              :finished="finished"
              finished-text="没有更多了"
              @load="onLoad"
              :offset="200"
            >
              <wxb-task-card
                @confirm="confirm"
                v-for="(item, index) of taskList"
                :info="item"
                :key="index"
                :type="item.attachType ? item.attachType : 3"
                :status="1"
                :showPerform="false"
              />
            </van-list>
            <wxb-no-data v-else />
          </van-pull-refresh>
        </div>
      </div>
    </div>
    <h5-navigater />
  </div>
</template>

<script>
  import home from "@/api/home";
  import task from "@/api/task";
  import clue from "../../api/clue";

  export default {
    name: "home",
    data() {
      return {
        showCrm: false,
        showPotential: false,
        crmList: [
          {
            name: "重点关注",
            class: "item-1",
            url: require("@/assets/images/icon_重点关注.png"),
            num: 0,
            shadow: require("@/assets/images/icon_重点关注_投影.png"),
          },
          {
            name: "新分配客户",
            class: "item-2",
            url: require("@/assets/images/icon_新分配客户.png"),
            num: 0,
            shadow: require("@/assets/images/icon_新分配客户_投影.png"),
          },
          {
            name: "3日内逾期",
            class: "item-3",
            url: require("@/assets/images/icon_三日内逾期.png"),
            num: 0,
            shadow: require("@/assets/images/icon_三日内逾期_投影.png"),
          },
          {
            name: "逾期未跟进",
            class: "item-4",
            url: require("@/assets/images/icon_逾期未跟进.png"),
            num: 0,
            shadow: require("@/assets/images/icon_逾期未跟进_投影.png"),
          },
        ],
        apps: [
          {
            permit: "visit",
            name: "来访登记",
            show: true,
            url: require("@/assets/images/icon_来访登记.png"),
          },
          {
            permit: "cme",
            name: "内容库",
            show: true,
            url: require("@/assets/images/icon_内容库.png"),
          },
          // {
          //   permit: 'AI',
          //   name: 'AI名片',
          //   show: true,
          //   url: require('@/assets/images/icon_内容库.png'),
          // },
          // {
          //   permit: 'all',
          //   name: '排版示意',
          //   url: require('@/assets/images/icon_排版示意1.png'),
          // }, {
          //   permit: 'all',
          //   name: '填充展示',
          //   url: require('@/assets/images/icon_排版示意2.png'),
          // }
        ],

        // 卡片分页
        pageNation: {
          pageNo: 0,
          pageSize: 20,
          totalRecords: 0,
        },
        loading: false,
        finished: false,
        refreshing: false,
        taskList: [],

        //消息提醒
        msgList: [],
        interval: "",
        //关键用户
        keyUserList: [],
      };
    },
    filters: {
      filterMsgType(val) {
        let str = val + "";
        if (str.substr(0, 1) === "1") {
          return "CRM";
        } else {
          return "潜客";
        }
      },
    },
    computed: {
      defaultImg() {
        return (
          'this.src="' + require("@/assets/images/default_head_icon.png") + '"'
        );
      },
      keyUserListSlice() {
        return this.keyUserList.slice(0, 4);
      },
    },
    created() {
      this.scroll();
      this.interval = setInterval(this.scroll, 20000);
    },
    methods: {
      async scroll() {
        let params = {
          opId: localStorage.getItem("platform_opId"),
          estateId: localStorage.getItem("platform_estateId"),
          pageNo: 1,
          pageSize: 5,
        };
        let { data, code } = await home.getMsgPage(params);
        if (code === "0") {
          this.msgList = data.data;
        } else {
          clearInterval(this.interval);
        }
      },
      async getMessageNum() {
        try {
          let { code, data } = await home.getMessageNum(
            localStorage.getItem("platform_estateId")
          );
          if (code === "0") {
            this.crmList[0].num = data.focusNum || 0;
            this.crmList[1].num = data.newAllocNum || 0;
            this.crmList[2].num = data.overdueIn3Num || 0;
            this.crmList[3].num = data.overdueNotFollowNum || 0;
          }
        } catch (e) {
          console.log(e);
        }
      },
      //go不同类型客户列表-scrm
      goCustomer(item) {
        let type = "";
        switch (item.name) {
          case "重点关注":
            type = "重点关注";
            break;
          case "新分配客户":
            type = "新分配客户";
            break;
          case "3日内逾期":
            type = "三日内逾期";
            break;
          case "逾期未跟进":
            type = "逾期未跟进";
            break;
          default:
            break;
        }
        window.location.href =
          `${window.location.protocol}//` +
          location.host +
          "/crm/#/" +
          `customerClass?type=${type}`;
      },
      //跳转应用
      junmpApp(item) {
        switch (item.name) {
          case "来访登记":
            window.location.href =
              `${window.location.protocol}//` + location.host + "/assistants/#/";
            break;
          case "内容库":
            window.location.href =
              `${window.location.protocol}//` + location.host + "/cme/#/";
            break;
          case "AI名片":
            this.openAiCard();
            break;
          default:
            break;
        }
      },
      // 打开AI卡片
      async openAiCard() {
        this.$router.push("/aiCard");
        // let { code } = await home.getAIUrl({
        //   tenantId: JSON.parse(localStorage.getItem('platform_user')).tenantId,
        //   saleName: JSON.parse(localStorage.getItem('platform_user')).name,
        //   salePhone: JSON.parse(localStorage.getItem('platform_user')).mobile,
        //   saleUserId: JSON.parse(localStorage.getItem('platform_role')).saleUserId,
        //   estateId: localStorage.getItem('platform_estateId'),
        //   opId: localStorage.getItem('platform_opId'),
        // });
        // if (code === '0') {
        //   this.$router.push('/aiCard');
      },
      //获取任务列表
      async getTaskList() {
        let params = {
          estateIds: localStorage.getItem("platform_estateId"),
          taskExecStatus: 0,
          pageNo: this.pageNation.pageNo,
          pageSize: this.pageNation.pageSize,
        };
        let { code, data } = await task.getTaskList(params);
        this.loading = false;
        if (code === "0") {
          let arr = data.data.map((item) => {
            if (item.taskType === 1) {
              return { ...item.spreadTask, taskType: item.taskType };
            } else if (item.taskType === 2) {
              return { ...item.addFriendTask, taskType: item.taskType };
            } else {
              return {};
            }
          });
          this.taskList = this.taskList.concat(arr);
          this.pageNation.totalRecords = data.totalRecords;
          if (this.taskList.length >= this.pageNation.totalRecords) {
            this.finished = true;
          }
        } else {
          this.finished = true;
        }
      },
      //下拉刷新
      onRefresh() {
        this.pageNation.pageNo = 0;
        // 清空列表数据
        this.finished = false;

        // 重新加载数据
        // 将 loading 设置为 true，表示处于加载状态
        this.loading = true;
        this.onLoad();
      },
      onLoad() {
        if (this.refreshing) {
          this.taskList = [];
          this.refreshing = false;
        }
        this.pageNation.pageNo += 1;
        this.getTaskList();
      },
      async confirm(taskId) {
        let { code } = await task.performTask({
          taskId,
          estateId: localStorage.getItem("platform_estateId"),
        });
        if (code === "0") {
          this.pageNation = {
            pageNo: 0,
            pageSize: 20,
            totalRecords: 0,
          };
          this.taskList = [];
          this.onLoad();
        }
      },

      //获取个人二维码
      async getQRcode() {
        let { code, data } = await task.getQrcode();
        if (code === "0") {
          localStorage.setItem("platform_myQRCode", data);
        }
      },

      //获取关键用户
      async getKeyUserList() {
        let query = {
          pageNo: 1,
          pageSize: 10,
        };
        const { data } = await clue.getKeyUserList(query);
        this.keyUserList = data.data;
      },

      handleScroll() {
        let scrollTop =
          window.pageYOffset ||
          document.documentElement.scrollTop ||
          document.body.scrollTop;
        // console.log('scrollTop---', scrollTop);
        let crmList = document.querySelector("#crmList");
        // console.log('crmList', crmList);
        // let top = document.querySelector('#top');
        if (scrollTop > 8) {
          crmList.style.opacity = 0.5;
          crmList.style.marginTop = "20px";
        } else {
          crmList.style.opacity = 1;
          crmList.style.marginTop = "12px";
        }
      },
    },
    async mounted() {
      this.onLoad();
      this.getQRcode();
      let appPermits = JSON.parse(localStorage.getItem("platform_appPermit"));
      this.showCrm = appPermits.some((item) => item === "scrm");
      if (this.showCrm) {
        this.getMessageNum();
      }
      let showVisit = appPermits.some((item) => item === "visit");
      this.showPotential = appPermits.some((item) => item === "potential");
      if (this.showPotential) {
        this.getKeyUserList();
      }
      if (!showVisit) {
        this.apps[0].show = false;
      }
    },
    destroyed() {
      clearInterval(this.interval);
    },
    beforeRouteLeave(to, from, next) {
      if (to.path == "/") {
        //必要的
        this.$wx.closeWindow();
      } else {
        next();
      }
    },
  };
</script>

<style lang="less" scoped>
.home {
  .box {
    position: relative;
    top: 113px;
    background: #f5f6f7;
    border-radius: 4px;
  }
  .top {
    position: fixed;
    top: 0;
    width: 100%;
    display: flex;
    justify-content: center;
    height: 113px;
    background: linear-gradient(180deg, #3974c6 0%, #6b9fe7 100%);
    .crm-list {
      width: 100%;
      height: 73px;
      margin: 12px;
      display: flex;
      box-sizing: border-box;
      justify-content: space-between;
      align-items: center;
      transition: all 0.2s linear;

      .list-item {
        display: flex;
        flex-direction: column;
        align-items: center;
        background: #fff;
        width: 79px;
        height: 73px;
        padding: 4px;
        box-sizing: border-box;
        border-radius: 8px;

        .bg {
          width: 100%;
          height: 36px;
          display: flex;
          background: linear-gradient(
            207deg,
            rgba(118, 143, 255, 0.12) 0%,
            rgba(58, 116, 242, 0.32) 100%
          );
          border-radius: 7px;
          align-items: center;
          justify-content: center;
          font-family: SSI_Black;
          font-size: 20px;
          color: #3a74f2;
          .bg-num {
            z-index: 3;
          }
        }
        .item-1 {
          background: linear-gradient(
            207deg,
            rgba(118, 143, 255, 0.12) 0%,
            rgba(58, 116, 242, 0.32) 100%
          );
          color: #3a74f2;
        }
        .item-2 {
          background: linear-gradient(
            27deg,
            rgba(0, 213, 135, 0.24) 0%,
            rgba(63, 229, 189, 0.08) 100%
          );
          color: #00d587;
        }
        .item-3 {
          background: linear-gradient(
            207deg,
            rgba(255, 220, 79, 0.08) 0%,
            rgba(255, 182, 39, 0.24) 100%
          );
          color: #ffb626;
        }
        .item-4 {
          background: linear-gradient(
            207deg,
            rgba(167, 182, 208, 0.08) 0%,
            rgba(110, 128, 164, 0.24) 100%
          );
          color: #6e80a4;
        }
        .name {
          color: @gary-wxb-black-1;
          margin-top: 8px;
          line-height: 17px;
          font-size: 12px;
        }
      }
    }
  }

  .remind-box {
    background: #ffffff;
    padding: 0px 12px 16px 12px;
  }
  .remind {
    position: relative;
    height: 53px;
    padding: 0px 12px;
    display: flex;
    align-items: center;
    border-radius: 4px;
    background: rgba(48, 49, 51, 0.02);
    .icon {
      width: 12px;
      height: 24px;
      position: absolute;
      right: 14px;
    }

    .remind-bg {
      position: absolute;
      top: 0px;
      left: 0px;
      width: 80px;
      height: 100%;
      z-index: 1;
    }

    .remind-title {
      color: #18d0cc;
      font-size: 15px;
      font-weight: bold;
      z-index: 2;
    }

    .remind-content {
      margin-left: 16px;
      margin-right: 16px;
      width: 239px;
      height: 37px;

      .time {
        font-size: 12px;
        font-weight: 400;
        color: @gary-wxb-gray-3;
        line-height: 17px;
      }

      .content {
        font-size: 14px;
        font-weight: bold;
        color: #606266;
        overflow: hidden; //超出的文本隐藏
        text-overflow: ellipsis; //溢出用省略号显示
        white-space: nowrap;

        span {
          color: #303133;
        }
      }
    }
  }

  .apps {
    border-radius: 8px 8px 0px 0px;
    padding: 12px 16px 12px 12px;
    display: flex;
    background: #fff;
    margin-top: -12px;

    .item-icon {
      width: 40px;
      height: 40px;
    }

    .app-item {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 87.75px;

      .item-name {
        margin-top: 4px;
        font-size: 12px;
        font-weight: 400;
        color: @gary-wxb-black-1;
        line-height: 17px;
      }
    }
  }

  .task {
    background: linear-gradient(
      180deg,
      #ffffff 0%,
      rgba(255, 255, 255, 0) 160px
    );
    padding-bottom: calc(89px + env(safe-area-inset-bottom));

    .task-title {
      padding: 16px 12px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .icon {
        width: 12px;
        height: 24px;
      }

      .title {
        font-size: 17px;
        font-weight: bold;
        color: @gary-wxb-black-1;
        line-height: 24px;
      }
    }

    .task-list {
      margin: 0px 12px 0px 12px;
    }
  }
  .keyUser-box {
    background: #fff;
    padding: 0 12px 12px 12px;
    box-sizing: border-box;
  }
  .keyUser-list {
    background: #fff;
    padding: 12px;
    background: rgba(48, 49, 51, 0.02);
    border-radius: 4px;
    box-sizing: border-box;
    position: relative;
    h6 {
      margin: 0;
      font-size: 14px;
      font-weight: 500;
      color: @gray3;
      line-height: 20px;
    }
    .keyUser-head {
      margin-top: 8px;
      img {
        width: 24px;
        height: 24px;
        border-radius: 50%;
        border: 1px solid #ebeef5;
        margin-left: -4px;
        box-sizing: border-box;
        &:first-child {
          margin-left: 0px;
        }
      }
    }
    p {
      margin: 4px 0 0 0;
      height: 17px;
      font-size: 12px;
      font-weight: 400;
      color: @gray9;
      line-height: 17px;
    }
    .icon-right {
      width: 12px;
      height: 24px;
      position: absolute;
      right: 12px;
      top: 0;
      bottom: 0;
      margin: auto;
    }
  }
}
</style>
